import {
  configureStore,
  combineReducers,
  Reducer,
  AnyAction,
  Middleware,
  createListenerMiddleware,
} from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
// import activitiesReducer, {
//   initialState as activitiesInitialState,
// } from './redux/activities'
import feedReducer, { initialState as feedInitialState } from './redux/feedSlice'
import authReducer, { initialState as authInitialState } from './redux/authSlice'
import appReducer, { initialState as appInitialState } from './redux/appSlice'
import userReducer, { initialState as userInitialState } from './redux/userSlice'
import categoriesReducer, {
  initialState as categoriesInitialState,
} from './redux/categoriesSlice'
// import postsReducer, { initialState as postsInitialState } from './redux/posts'
// import postsNewReducer, { initialState as postsNewInitialState } from './redux/postsNew'
// import activityReducer, {
//   initialState as activityInitialState,
// } from './redux/activitySlice'
// import publishedServicesReducer, {
//   initialState as publishedServicesInitialState,
// } from './redux/publishedServices'
import searchReducer, { initialState as searchInitialState } from './redux/searchSlice'
// import profileReducer, { initialState as profileInitialState } from './redux/profile'
// import chatsReducer, { initialState as chatsInitialState } from './redux/chats'
import messengerReducer, {
  initialState as messengerInitialState,
} from './redux/messengerSlice'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'app', 'user'],
}

const combinedReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  user: userReducer,
  messenger: messengerReducer,
  categories: categoriesReducer,
  // activities: activitiesReducer,
  // posts: postsReducer,
  // postsNew: postsNewReducer,
  // activity: activityReducer,
  // publishedServices: publishedServicesReducer,
  // chats: chatsReducer,
  // profile: profileReducer,
  feed: feedReducer,
  search: searchReducer,
})

const initialState = {
  app: appInitialState,
  auth: authInitialState,
  user: userInitialState,
  categories: categoriesInitialState,
  // activities: activitiesInitialState,
  // activity: activitiesInitialState,
  // publishedServices: publishedServicesInitialState,
  // posts: postsInitialState,
  // postsNew: postsNewInitialState,
  // profile: profileInitialState,
  // chats: chatsInitialState,
  // userNew: userNewInitialState,
  feed: feedInitialState,
  search: searchInitialState,
  messenger: messengerInitialState,
}

export const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'auth/logout') {
    return combinedReducer({ ...initialState, app: state.app }, action)
  }
  return combinedReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const listenerMiddleware = createListenerMiddleware()

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }).prepend(listenerMiddleware.middleware),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof combinedReducer>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
