import { lazy, Suspense } from 'react'
import useSessionExpiration from './utils/hooks/useSessionExpiration'
import AppFallback from './components/Layouts/AppFallback'

const AppRoutes = lazy(() => import('./utils/routes/AppRoutes'))
export default function App() {
  useSessionExpiration()
  return (
    <Suspense fallback={<AppFallback />}>
      <AppRoutes />
    </Suspense>
  )
}
